import Cookies from 'js-cookie';
import actions from './actions';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr, storeAccessToken, storeDetails } =
  actions;

const login = () => {
  return async (dispatch) => {
    try {
      dispatch(loginBegin());
      setTimeout(() => {
        // Cookies.set('logedIn', true);
        return dispatch(loginSuccess(true));
      }, 1000);
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const storeAccessTokenInRedux = (token) => {
  console.log('token', token);
  return async (dispatch) => {
    try {
      dispatch(storeAccessToken(token));
    } catch (err) {
      // dispatch()
    }
  };
};

const logOut = () => {
  return async (dispatch) => {
    try {
      dispatch(logoutBegin());
      Cookies.remove('logedIn');
      localStorage.clear();
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

const storeUserDetails = (data) => {
  console.log('storeUserDetails', data);
  return async (dispatch) => {
    try {
      dispatch(storeDetails(data));
    } catch (err) {
      // dispatch()
    }
  };
};

export { login, logOut, storeAccessTokenInRedux, storeUserDetails };
